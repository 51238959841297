<template>
	<div class="w-full">
		<div v-if="scoringShow && !isLoading">
			<div class="flex items-center justify-center w-full my-6">
				<ThreeButton v-if="proposalInformation.isSoftware && totalApprovedVendorProposal > 1" :options="options1"
					:value="activeScoring"
					@click="changeActiveScoring" 
					:isPoint="isPointLogic"
					:pointRed="pointRed1"
					:class="{'w-min min-w-custom': projectDetailContainerWidth > 640}" />
				<ThreeButton v-else-if="proposalInformation.isSoftware && totalApprovedVendorProposal === 1" :options="options2" 
					:value="activeScoring" 
					@click="changeActiveScoring" 
					:pointRed="pointRed2"
					:isPoint="isPointLogic"
					:class="{'w-min min-w-custom': projectDetailContainerWidth > 640}" />
				<ThreeButton v-else :options="options3" 
					:value="activeScoring" 
					@click="changeActiveScoring"
					:pointRed="pointRed3"
					:isPoint="isPointLogic"
					:class="{'w-min min-w-custom2': projectDetailContainerWidth > 640}" />
			</div>
			<div class="w-full">
				<TableApproverScoring v-if="activeScoring !== decisionText" :activeScoring="activeScoring"/>
				<scoring-price v-if="activeScoring === vendorText && scoringPriceLogApproval?.isScoringPrice && !scoringPriceLogApproval?.isAnyVendorThatNeedDoScoringTech && !scoringPriceLogApproval.isNeedApprovalScoringTech && !proposalInformation?.isAnyVendorThatHaveNotBeenQualified && !scoringPriceLogApproval?.isAnyVendorThatHaveNotBeenQualified"></scoring-price>
				<scoring-vendor v-else-if="activeScoring === vendorText"></scoring-vendor>
				<scoring-escrow v-if="activeScoring === escrowText"></scoring-escrow>
				<scoring-decision v-if="activeScoring === decisionText"></scoring-decision>
			</div>
		</div>
		<div v-if="!scoringShow && !isLoading"
			class="flex items-center justify-center height-custom">
			<div class="flex">
				<p class="mr-3 text-2xl icon-alert-circle text-gray"></p>
				<p class="text-base font-medium text-gray">Proses Scoring belum di mulai</p>
			</div>
		</div>
		<ScoringLoading v-if="isLoading"/>
	</div>
</template>
<script>
import ScoringEscrow from '@/core/components/rfp/ScoringEscrow.vue';
import ScoringVendor from '@/core/components/rfp/ScoringVendor.vue';
import ScoringPrice from '@/core/components/rfp/ScoringPrice'
import ScoringDecision from '@/core/components/rfp/ScoringDecision.vue';
import ScoringLoading from '@/core/components/rfp/loading/ScoringLoading.vue';
import ThreeButton from '@/core/components/custom/ThreeButton.vue';
import { QUALIFICATION_ELIMINATED, PRA_QUALIFICATION_ELIMINATED,  } from '@/core/constant/qualification.js'
import TableApproverScoring from '@/core/components/rfp/TableApproverScoring.vue';
import { VENDOR_01_AWAITING_PRE_QUALIFICATION_DOCUMENT, CANCELED } from '@/core/constant/statusProject'
import { PATH_ROOT, PROJECT_DETAIL } from '@/core/constant/routeName'
export default {
	name: "Scoring",
	data() {
		return {
			isLoading: true,
			options1: ['Vendor', 'Escrow', 'Decision'],
			options2: ['Escrow', 'Decision'],
			options3: ['Vendor', 'Decision'],
			vendorText: 'Vendor',
			escrowText: 'Escrow',
			decisionText: 'Decision',
		};
	},
	computed: {
		activeScoring: {
			get() {
				return this.$store.state.projectDetail.activeScoring;
			},
			set(value) {
				this.$store.commit("projectDetail/SET_ACTIVE_SCORING", value);
			}
		},
		loading: {
			get() {
				return this.$store.state.modal.loading
			},
			set(value) {
				this.$store.commit('modal/SET_LOADING', value)
			}
		},
		proposalInformation() {
			return this.$store.state.projectDetail.proposalInformation
		},
		qualifications() {
			return this.$store.state.qualification.qualifications.filter(e => e.statusVendor !== QUALIFICATION_ELIMINATED && e.statusVendor !== PRA_QUALIFICATION_ELIMINATED)
		},
		totalApprovedVendorProposal() {
			return this.$store.state.qualification.totalApprovedVendorProposal
		},
		projectLogRfp() {
			return this.$store.state.projectLog.projectLogRfp
		},
		scoringShow() {
			return this.proposalInformation.isVisibleScoring
		},
		userDetail() {
			return this.$store.state.dashboard.userProfile;
		},
		scoringPriceLogApproval() {
			return this.$store.state.negotiation.scoringPriceLogApproval
		},
		projectDetailContainerWidth() {
			return this.$store.state.width.projectDetailContainerWidth
		},
		scoringVendorApproval() {
      return this.$store.state.projectDetail.scoringVendorApproval
    },
		approvalGsit() {
      return this.scoringVendorApproval?.find(e => e.unitKerja === 'GSIT')
    },
    approvalUkkp() {
      return this.scoringVendorApproval?.find(e => e.unitKerja === 'UKKP')
    },
    approvalDlog() {
      return this.scoringVendorApproval?.find(e => e.unitKerja === 'ITP')
    },
		isPointLogic() {
			if (this.approvalGsit?.approvalStatusLocale?.toLowerCase() === 'on review') {
        return this.userDetail.username.toLowerCase() === this.approvalGsit?.userId?.toLowerCase()
      } else if (this.approvalUkkp?.approvalStatusLocale?.toLowerCase() === 'on review') {
        return this.userDetail.username.toLowerCase() === this.approvalUkkp?.userId?.toLowerCase()
      } else if (this.approvalDlog?.approvalStatusLocale?.toLowerCase() === 'on review') {
        return this.userDetail.username.toLowerCase() === this.approvalDlog?.userId?.toLowerCase()
      } else {
        return false
      }
		},
		pointRed1() {
			const vendor = this.scoringVendorApproval.some(e => e.needReviewTechScoring)
			const escrow = this.scoringVendorApproval.some(e => e.needReviewEscrowScoring)
			const decision = this.scoringVendorApproval.some(e => e.needReviewDecisionScoring)
			return [ vendor, escrow, decision ]
		},
		pointRed2() {
			const escrow = this.scoringVendorApproval.some(e => e.needReviewEscrowScoring)
			const decision = this.scoringVendorApproval.some(e => e.needReviewDecisionScoring)
			return [ escrow, decision ]
		},
		pointRed3() {
			const vendor = this.scoringVendorApproval.some(e => e.needReviewTechScoring)
			const decision = this.scoringVendorApproval.some(e => e.needReviewDecisionScoring)
			return [ vendor, decision ]
		},
		projectCanceledByLogs() {
			return this.projectLogRfp.rfpLogs.some(e => e.statusProject === CANCELED)
		},
		projectCanceled() {
			return this.sppProcess || this.projectCanceledByLogs
		},
		sppProcess() {
			return this.$store.state.spp.sppProcess
		},
		scoringShowTab() {
			const visibleScoring =  this.proposalInformation.isVisibleScoring
			return this.pradetailShow && this.noSingleVendor && (!this.projectCanceled || visibleScoring)
		},
		qualificationActive() {
			const statusVendor = [ QUALIFICATION_ELIMINATED, PRA_QUALIFICATION_ELIMINATED ]
			return this.qualifications.filter(e => !statusVendor.includes(e.statusVendor))
		},
		noSingleVendor() {
			return this.qualificationActive.length > 1 || this.isSoftware
		},
		isSoftware() {
			return this.proposalInformation.isSoftware
		},
		pradetailShow() {
			const statusProject = [VENDOR_01_AWAITING_PRE_QUALIFICATION_DOCUMENT]
			return this.projectLogRfp?.rfpLogs.some(e => statusProject.includes(e.statusProject))
		},
		activePage: {
			get() {
				return this.$store.state.projectDetail.activePage
			},
			set(value) {
				this.$store.commit('projectDetail/SET_ACTIVE_PAGE', value)
			}
		},
	},
	methods: {
		changeActiveScoring(value) {
			this.activeScoring = value;
		},
		initialActiveScoring() {
			if (this.proposalInformation.isSoftware && this.totalApprovedVendorProposal === 1) {
				this.activeScoring = this.escrowText
			} else {
				this.activeScoring = this.vendorText
			}
		},
		setActivePage(paramProjectId) {
			if (!this.scoringShowTab) {
				this.activePage = PROJECT_DETAIL
				this.$router.push({ path: `/${PATH_ROOT}/${PROJECT_DETAIL}/${paramProjectId}` }).catch(() => ({}))
			}
		},
	},
	async mounted() {
		this.isLoading = true
		this.loading.loadingProjectLog = true
		const res = await this.$store.dispatch('projectLog/getCheckAccess', { projectRequestId: this.$route.params.projectId, projectRequestType: 'rfp' })
		console.log(res, res?.data.code, 'get access');
    if (res?.data.code !== 200) return
		const r1 = this.$store.dispatch('projectDetail/getProposalInformation', this.$route.params.projectId)
		const r2 = this.$store.dispatch('qualification/getQualifications', this.$route.params.projectId)
		const r3 = this.$store.dispatch('negotiation/getScoringPriceLogApproval', { rfpId: this.$route.params.projectId })
		const r4 = this.$store.dispatch('projectLog/getProjectLogByRfpId', { rfpId: this.$route.params.projectId })
    const r5 = this.$store.dispatch('projectDetail/getScoringVendorApproval', { rfpId: this.$route.params.projectId })
		const [res1, res2, res3, res4, res5] = await Promise.all([r1, r2, r3, r4, r5])
		this.setActivePage(this.$route.params.projectId)
		if (res1?.status === 200 && res2?.status === 200 && res3?.status === 200 && res5?.status === 200 ) {
			this.initialActiveScoring()
			this.isLoading = false
		}
		if (res4?.status === 200 ) {
			this.loading.loadingProjectLog = false
		}
	},
	components: {
		ScoringEscrow,
		ScoringVendor,
		ScoringDecision,
		ScoringLoading,
		ThreeButton,
		ScoringPrice,
		TableApproverScoring,
	}
}
</script>

<style scoped>
.height-custom {
	min-height: 30rem;
}
.min-w-custom {
	min-width: 30rem;
}
.min-w-custom2 {
	min-width: 20rem;
}
</style>