<template>
  <div class="w-full">
    <div class="relative flex w-full my-6" :class="projectDetailContainerWidth < 640 && !isSingleVendor ? 'gap-2.5 flex-col items-start' : 'items-center '">
      <span class="text-2xl cursor-pointer icon-arrow-left text-gray" @click="() => isOpenCancel = true"></span>
      <ModalConfirm 
        :title="`Batalkan Pembuatan ${isSingleVendor? 'LOI': draftSktLoi.needLoi? 'SKT & LOI' : 'SKT'}?`" 
        message="Informasi yang diisi akan hilang, apakah anda yakin?" 
        icon="rejected" 
        textButton="Yes" 
        :isOpen="isOpenCancel" 
        @changeIsOpen="() => isOpenCancel = false" 
        @click="clickCancel"
        colorButton="error"></ModalConfirm>
      <div class="flex justify-center w-full">
        <ThreeButton :options="optionsTab"
          :disabled="[false, needLoiOption || !draftSktLoi.needLoi]"
          @click="changeActiveButton"
          v-if="!isSingleVendor"
          :value="activeButton"
          :class="{'w-min min-w-custom': projectDetailContainerWidth > 640}" />
          <div v-else>
            <h4 class="text-lg font-medium text-gray-darkest">Create LOI</h4>
          </div>
      </div>
      <div v-if="!isSingleVendor && draftSktLoi.needLoi && showLoi" class="absolute right-0 text-base font-medium cursor-pointer text-error" @click="cancelLoi" >
        <span class="w-6 icon-x"></span>
        Cancel LOI
      </div>
    </div>
    <div>
      <Information />
      <CardVendorSkt v-show="showSkt" />
      <CardLoi v-show="!showSkt" />
      <Approval :showSkt="showSkt" ref="approval"/>
    </div>
    <div class="pt-6 mt-6 border-t-2 border-gray-lightest ">
      <div class="flex justify-between" :class="projectDetailContainerWidth > 640 ? '' : 'flex-col gap-6 w-full'">
        <SaveAsDraftSktLoi v-if="isSingleVendor || (needLoiOption && showSkt) || (!needLoiOption && showSkt && !draftSktLoi.needLoi) || (!needLoiOption && !showSkt && draftSktLoi.needLoi)" @click="saveAsDraft" />
        <div class="flex" :class="projectDetailContainerWidth > 640 ? '' : 'flex-col gap-6 w-full'">
          <LoiNotCreated @click="changeDefaultNeedLoi" v-if="!needLoiOption && !draftSktLoi.needLoi && !isSingleVendor" />
          <NeedToCreateLoi v-if="needLoiOption && !isSingleVendor" @click="handleClickNeedLoi" />
          <SubmitSktLoi v-else-if="isSingleVendor ? true : draftSktLoi.needLoi ? !showSkt : draftSktLoi.needLoi === false" 
            :title="`Buat ${isSingleVendor? 'LOI' : draftSktLoi.needLoi? 'SKT & LOI' : 'SKT'}?`"
            @click="submitSktLoi" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SaveAsDraftSktLoi from '@/core/components/rfp/sktLoi/SaveAsDraftSktLoi.vue'
import SubmitSktLoi from '@/core/components/rfp/sktLoi/SubmitSktLoi.vue'
import NeedToCreateLoi from '@/core/components/rfp/sktLoi/NeedToCreateLoi.vue'
import LoiNotCreated from '@/core/components/rfp/sktLoi/LoiNotCreated.vue'
import ThreeButton from '@/core/components/custom/ThreeButton.vue';
import { MESSAGE_SUCCESS_CREATE_SKT, MESSAGE_SUCCESS_CREATE_SKT_LOI, MESSAGE_SUCCESS_CREATE_LOI } from '@/core/constant/successMessage'
import Information from '@/core/components/rfp/sktLoi/Information.vue'
import CardVendorSkt from '@/core/components/rfp/sktLoi/CardVendorSkt.vue';
import Approval from '@/core/components/rfp/sktLoi/Approval.vue'
import CardLoi from '@/core/components/rfp/sktLoi/CardLoi.vue'
import { PATH_ROOT } from '@/core/constant/routeName'
import ModalConfirm from '@/core/components/modal/Confirm'
import { MESSAGE_POPUP_VALIDATION_SUBMIT, MESSAGE_EMPTY_FIELD } from '@/core/constant/errorMessage.js'

export default {
  name: "CreateSktLoi",
  data() {
    return {
      optionsTab: ['Create SKT', 'Create LOI'],
      isOpenCancel: false,
      createLoi: 'Create LOI',
      createSkt: 'Create SKT'
    }
  },
  computed:{
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
    needLoiOption: {
      get() {
        return this.$store.state.sktLoi.needLoiOption
      },
      set(value) {
        this.$store.commit('sktLoi/SET_NEED_LOI_OPTION', value)
      }
    },
    showSkt() {
      return this.activeButton === this.createSkt
    },
    showLoi() {
      return this.activeButton === this.createLoi
    },
    activeButton: {
      get() {
        return this.$store.state.sktLoi.activeButton
      },
      set(val) {
        this.$store.commit('sktLoi/SET_ACTIVE_BUTTON', val)
      }
    },
    approvals() {
      return this.$store.state.sktLoi.approvals
    },
    draftSktLoi: {
      get() {
        return this.$store.state.sktLoi.draftSktLoi
      },
      set(value) {
        this.$store.commit('sktLoi/SET_DRAFT_SKT_LOI', value)
      }
    },
    draftSktLoiDeepClone() {
      return this.$store.state.sktLoi.draftSktLoiDeepClone
    },
    isSingleVendor() {
      return this.draftSktLoi.sktVendors?.length === 0
    },
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    }
  },
  methods: {
    changeActiveButton(e) {
      this.activeButton = e
    },
    changeDefaultNeedLoi() {
      this.needLoiOption = true
    },
    handleClickNeedLoi(e) {
      this.draftSktLoi.needLoi = e
      this.needLoiOption = false
      if(e) this.activeButton = this.createLoi
    },
    cancelLoi() {
      this.needLoiOption = true
      this.draftSktLoi.needLoi = false
      this.activeButton = this.createSkt
      this.draftSktLoi = { ...this.draftSktLoi, loiVendors: this.draftSktLoiDeepClone.loiVendors }
    },
    setErrorApprovals() {
      if (!this.approvals.APPROVER_DLOG?.search) {
        this.draftSktLoi.error['Approver ITP'] = MESSAGE_EMPTY_FIELD
      } else {
        this.draftSktLoi.error['Approver ITP'] = ''
      }
      if (!this.approvals.APPROVER_GSIT?.search) {
        this.draftSktLoi.error['Approver GSIT'] = MESSAGE_EMPTY_FIELD
      } else {
        this.draftSktLoi.error['Approver GSIT'] = ''
      }
    },
    ifElseSetVendors(field, keyError, sktLoi, i) {
      if (!field) this.draftSktLoi[sktLoi][i].error[keyError] = MESSAGE_EMPTY_FIELD
      else this.draftSktLoi[sktLoi][i].error[keyError] = ''
    },
    setErrorVendors(sktLoi) {
      for (const [i,s] of this.draftSktLoi[sktLoi].entries()) {
        this.ifElseSetVendors(s.vendorPic, 'PIC Vendor Principal', sktLoi, i)
        this.ifElseSetVendors(s.vendorEmail, 'Email Vendor Principal', sktLoi, i)
        if (s.localPartnerName) {
          this.ifElseSetVendors(s.localPartnerPic, 'PIC Local Partner', sktLoi, i)
          this.ifElseSetVendors(s.localPartnerEmail, 'Email Local Partner', sktLoi, i)
        }
      }
    },
    setErrorAll() {
      this.setErrorApprovals()
      if (this.isSingleVendor) {
        this.setErrorVendors('loiVendors')
      } else if (this.draftSktLoi.needLoi) {
        this.setErrorVendors('sktVendors')
        this.setErrorVendors('loiVendors')
      } else {
        this.setErrorVendors('sktVendors')
      }
    },
    checkErrorLoi({ isSubmit }) {
      let errorLoi = []
      for (const l of this.draftSktLoi.loiVendors) {
        if (Object.values(l.error).some(el => ((el && el !== MESSAGE_EMPTY_FIELD && !isSubmit) || (el && isSubmit)))) {
          const text = l.localPartnerName? `LOI Local Partner #${l.sequence}` : `LOI Vendor #${l.sequence}`
          errorLoi.push(text)
        }
      }
      return errorLoi
    },
    checkErrorSkt({ isSubmit }) {
      let errorSkt = []
      for (const s of this.draftSktLoi.sktVendors) {
        if (Object.values(s.error).some(el => ((el && el !== MESSAGE_EMPTY_FIELD && !isSubmit) || (el && isSubmit)))) {
          const text = s.localPartnerName? `SKT Local Partner #${s.sequence}` : `SKT Vendor #${s.sequence}`
          errorSkt.push(text)
        }
      }
      return errorSkt
    },
    async checkErrorSaveAsDraft() {
      const errorLoi = this.checkErrorLoi({ isSubmit: false })
      const errorSkt = this.checkErrorSkt({ isSubmit: false })
      if (this.isSingleVendor) {
        return [ ...errorLoi ]
      } else if (this.draftSktLoi.needLoi) {
        return [ ...errorSkt, ...errorLoi ]
      } else {
        return [ ...errorSkt ]
      }
    },
    async checkErrorSubmit() {
      await this.setErrorAll()
      const errorApprovals = Object.keys(this.draftSktLoi.error).filter(e => this.draftSktLoi.error[e])
      const errorLoi = this.checkErrorLoi({ isSubmit: true })
      const errorSkt = this.checkErrorSkt({ isSubmit: true })
      if (this.isSingleVendor) {
        return [ ...errorLoi, ...errorApprovals ]
      } else if (this.draftSktLoi.needLoi) {
        return [ ...errorSkt, ...errorLoi, ...errorApprovals ]
      } else {
        return [ ...errorSkt, ...errorApprovals ]
      }
    },
    async saveAsDraft() {
      this.modal.modalLoading = true
      const checkErrorSaveAsDraft = await this.checkErrorSaveAsDraft()
      const messageError = checkErrorSaveAsDraft.join(', ')
      if (checkErrorSaveAsDraft.length) {
        this.modal.modalLoading = false
        this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + messageError }
        return
      }
      const response = this.draftSktLoi.lastModifiedAt? await this.$store.dispatch('sktLoi/updateDraftSkt') : await this.$store.dispatch('sktLoi/saveAsDraftSkt')
      if (response?.status < 300) {
        this.$emit('setShowButtonContinueDraft', true)
        await this.$store.dispatch('procurementApproval/getApproval', { rfpId: this.$route.params.projectId })
        await this.$store.dispatch('sktLoi/getSkt', { rfpId: this.$route.params.projectId })
        this.modal.modalLoading = false
      } else {
        this.modal.modalLoading = false
      }
    },
    async submitSktLoi() {
      this.modal.modalLoading = true
      const checkErrorSubmit = await this.checkErrorSubmit()
      const messageError = checkErrorSubmit.join(', ')
      if (checkErrorSubmit.length) {
        this.modal.modalLoading = false
        this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + messageError }
        return
      }
      const response = this.draftSktLoi.lastModifiedAt? await this.$store.dispatch('sktLoi/updateSubmitSkt') : await this.$store.dispatch('sktLoi/submitDraftSkt')
      if (response?.status) {
        await this.$store.dispatch('procurementApproval/getApproval', { rfpId: this.$route.params.projectId })
        this.$store.dispatch('projectLog/refetchSktLoiLog', { rfpId: this.$route.params.projectId })
        this.setModalSubmitted()
        this.modal.modalLoading = false
      } else {
        this.modal.modalLoading = false
      }
    },
    setModalSubmitted() {
      this.modal.modalSuccessSubmitted = { value: true, title: this.draftSktLoi.needLoi && this.isSingleVendor ? MESSAGE_SUCCESS_CREATE_LOI : this.draftSktLoi.needLoi && !this.isSingleVendor? MESSAGE_SUCCESS_CREATE_SKT_LOI : MESSAGE_SUCCESS_CREATE_SKT }
    },
    async clickCancel() {
      const response = await this.$store.dispatch('sktLoi/cancelSkt', { rfpId: this.$route.params.projectId })
      if (response?.status) {
        const query = await this.$store.dispatch('dashboard/setQueryBacktoHome')
			  this.$router.push({ path: `/${PATH_ROOT}`, query }).catch(() => ({}))
      }
    },
  },
  mounted() {
    if (this.isSingleVendor) {
      this.handleClickNeedLoi(true)
    }
  },
  components:{
    SubmitSktLoi,
    SaveAsDraftSktLoi,
    ThreeButton,
    NeedToCreateLoi,
    LoiNotCreated,
    Information,
    CardVendorSkt,
    Approval,
    CardLoi,
    ModalConfirm
  }
}
</script>

<style scoped>
.min-w-custom {
	min-width: 20rem;
}
</style>